import {
  SAVE_LE_DATA,
  REMOVE_LE_DATA,
  GET_LE_DATA
} from '../../actions/Constants';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function leReducer(state = defaultState, action) {
  switch (action.type) {

    case SAVE_LE_DATA:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case REMOVE_LE_DATA:
      return {
        ...state,
        details: {},
        error: null,
        loading: false
      };
    case GET_LE_DATA:
      return {
        ...state,
        error: null,
        loading: false
      };

    default:
      return state;
  }
}