import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {baseURL, ALL_DATA} from '../actions/Constants';
import FilterView from '../components/filterView/FilterView';
import ExcelExportHelper from "../components/ExcelExportHelper";
import TabView from '../components/tabView/TabView';

import ReportDetails from './Report/ReportDetails';
import SummaryDetails from './Report/SummaryDetails';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import Loading from '../components/loading/Loading';
import {SampleReportData} from '../util/DefaultInitialValues';
import {getTimeStampsFromDate, getEndDateEpoc} from '../util/DateTimeConverter';

import attendance_report_icon from '../assets/images/attendance_report_icon.png';

const Report = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(null);
  const [ReportData, SetReportData] = useState({});
  const [SummaryData, SetSummaryData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [MONTH_DAYS, setMonthDays] = useState(31);
  const [daysArray, setDaysArray] = useState([]);

  const {
    details
  } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

  useEffect(() => {
    if(details && !isEmptyJson(details)) {
      setFilters(details); 
    }
  }, [details]);

  useEffect(() => {
    if(filters !== null && emp_legal_entity_id !== "") {
      fetchReportData();
    } else {
      //navigate("/configure-device");
    }
  }, [filters]);

  const fetchReportData = async () => {
    if(emp_legal_entity_id === "") {
      navigate("/configure-device");
    }
    setLoading(true);
    //const URL=`${baseURL}/generateAllLEAttDataByMonthReportCSV_Template`;
    const URL=`${baseURL}/generateAllLEAttDataByMonthReportCSV_Template_DateRange`;

    const startTimeStampValue = getTimeStampsFromDate(startDate);
    const endTimeStampValue = getEndDateEpoc(endDate);

    let obj = {};
    obj.emp_legal_entity_id =  emp_legal_entity_id;
    obj.auto_calculate_month = false;
    obj.monthbegintimestamp = "" + startTimeStampValue; // 1713897000000
    obj.monthendtimestamp = "" + endTimeStampValue; // 1716489000000
    obj.office_id = office_id === "all" ? "" : office_id;
    obj.emp_id_pattern = emp_id_pattern === "all" ? "" : emp_id_pattern;
    
    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj) // body data type must match "Content-Type" header
     
    })
    .then(res => res.json())
    .then((res) => {
      const data = res; //ALL_DATA
      
      SetReportData(data.sheet_data[0]);
      SetSummaryData(data.sheet_data[1]);
      setLoading(false);
      setError(false);
    })
    .catch((error) => {
      console.log('error generateAllLEAttDataByMonthReportCSV_Template', error);
      setLoading(false);
      setError(true);
    });
  }

  const reportTab = () => {
    return (
      <ReportDetails data = {ReportData} MONTH_DAYS = {MONTH_DAYS} daysArray = {daysArray} />
    );
  }

  const summaryTab = () => {
    return (
      <SummaryDetails data = {SummaryData}  MONTH_DAYS = {MONTH_DAYS} daysArray = {daysArray} />
    );
  }

  const isEmptyJson = (jsonToTest  = {}) => {
    return (JSON.stringify({}) == JSON.stringify(jsonToTest))
  }

  useEffect(() => {
    setMonthDays(parseInt(ReportData.label_dates));
    const firstRowData = ReportData?.attendance_data;
    
    if(firstRowData && firstRowData[0]) {
      const dates = firstRowData[0]?.dates;

      if(dates && Array.isArray(dates)) {
        const datesList = dates.map(item => item.date_of_month);
        setDaysArray(datesList);
      }
    }

  }, [ReportData]);
 
  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = "",
    startDate = "",
    endDate = ""
  } = filters || {};

  const titleVal = "Report - OneAttendance";

  //const MONTH_DAYS = ReportData && parseInt(ReportData.label_dates) || 31;

  const TabListData = [reportTab, summaryTab];
  const TabsData = ["Report", "Summary"];

  return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
      </Helmet>

      <div className=" mb-3 filter_section">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <div> 
              <img src={attendance_report_icon} className="page_logo" style={{width: '90px'}} alt="" /> 
            </div>

            <div>
              <div className="page_h1_heading">Attendance Report</div>
              <div className="page_le_text">{filters && filters?.emp_legal_entity_id}</div>
            </div>
          </div>
        </div>

        <FilterView filters={filters} onHandleGo={data => setFilters(data)} isShow={true} />
      </div>

      {isLoading ? <Loading /> : <>
        {!isEmptyJson(ReportData) && !isEmptyJson(SummaryData) && 
          <ExcelExportHelper 
            reportData = {ReportData} 
            summaryData = {SummaryData} 
            MONTH_DAYS = {MONTH_DAYS}
            daysArray = {daysArray}
          />
        }

        {!isEmptyJson(ReportData) && !isEmptyJson(SummaryData) && <TabView TabsData = {TabsData} TabListData={TabListData}  MONTH_DAYS={MONTH_DAYS} />}
      </>
      }
      {isError && <CustomizedSnackbars />}
    </>
  );
};

export default Report;
