import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import CheckBoxView from '../../components/checkbox/CheckBoxView';
import Loading from '../../components/loading/Loading';
import TableView from '../../components/table/TableView';

import {updateWebAccessControlByLE, getWebAccessControlByLE} from '../../actions/webaccess.actions';
import {IntitialOverAllAccessList} from '../../util/DefaultInitialValues';
import {isEmptyJson} from '../../util/utils';

const WebAccessControl = (props) => {
	const [roleList, setRoleList] = useState([]);
	const [columns, setColumns] = useState([]);
	const [columns_feature, setFeatureColumns] = useState([]);
	
	const dispatch = useDispatch();
	const updateWebAccessControlByLEAction = updateWebAccessControlByLE;
	const getWebAccessControlByLEAction = getWebAccessControlByLE;

	const addNewPage = false;

	const {
    	details,
    	loading,
    	error
  	} = useSelector(({ webAccess: { details, loading, error } }) => ({ details, loading, error }));

  	const [overAllAccessList, setOverAllAccessList] = useState(details || IntitialOverAllAccessList);
	const [accessList, setAccessList] = useState(details?.accessControlList || []);
	const [featureList, setFeatureList] = useState(details?.featureControlList || []);

  	const {
    	details: payrollDetails
  	} = useSelector(({ payroll: { details, loading, error } }) => ({ details, loading, error }));

  	const {
    	details: filterDetails
  	} = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

	useEffect(() => {
		if(details && !isEmptyJson(details) && details?.accessControlList) {
			setOverAllAccessList(details);
			setAccessList(details?.accessControlList);
			setFeatureList(details?.featureControlList);
		}
	}, [details]);

	useEffect(() => {
		
		if(payrollDetails && Array.isArray(payrollDetails)) {
			const parseJson = payrollDetails && payrollDetails[0] && JSON.parse(payrollDetails[0].legal_entity_configuration)
			
			const roleJson = parseJson?.emp_role_details;
			const extractedRoleList = roleJson.map(item => item?.emp_role_name?.toLowerCase());
			
			if(extractedRoleList && Array.isArray(extractedRoleList)) {
				const notInAccessList = extractedRoleList.filter(item => !(accessList && accessList[0].permission.some(obj => obj?.role === item)));
				
				const updatedAccesList = accessList.map((eachAccessPageObj, idx) => {
					notInAccessList.map((role, idx) => {
						const obj = {};
						obj.role = role;
						obj.access = false;
						eachAccessPageObj.permission.push(obj); 
					});
					
					return eachAccessPageObj;
				});

				const notInFeatureList = extractedRoleList.filter(item => !(featureList && featureList[0].permission.some(obj => obj?.role === item)));

				const updatedFeatureList = featureList.map((eachFeaturePageObj, idx) => {
					notInFeatureList.map((role, idx) => {
						const obj = {};
						obj.role = role;
						obj.access = false;
						eachFeaturePageObj.permission.push(obj); 
					});
					
					return eachFeaturePageObj;
				});

				setAccessList(updatedAccesList);
				setFeatureList(updatedFeatureList);
				setRoleList(extractedRoleList);
			}
		}
	}, [payrollDetails]);

	useEffect(() => {
		createAccessColumns();
		createFeatureColumns();
	}, [roleList]);

	useEffect(() => {
		dispatch(getWebAccessControlByLEAction(filterDetails?.emp_legal_entity_id));
	}, []);

    const createAccessColumns = () => {
    	const columnList = [
		    {
		        name: "page_name",
		        label: 'Page Name',
		        options: {
		            filter: true,
		            sort: true,
		            customBodyRender: (value) => {
		                return (
		                    <div className="fw-bold">
		                        {value}
		                    </div>
		                );
		            }
		        }
		    },
		    ...roleList.map(role => ({
		        name: "page_url",
		        label: role, // Set the label as the role (e.g., Administrator)
		        options: {
		            filter: true,
		            sort: true,
		            customBodyRender: (value) => {
		                const pageObj = accessList.find(accssItem => accssItem?.page_url === value);
		                const permission = pageObj?.permission || [];

		                const roleWisePermissionObj = permission.find(per => per.role === role);
		                const accessValue = roleWisePermissionObj?.access || false;

		                return (
		                    <div>
		                        <CheckBoxView
		                        	name={`${value}_${role}`}
		                            value={accessValue}
		                            onChange={() => onHandleAccessChange(value, role, accessValue)}
		                            isDisabled={role === 'administrator'} // Disable the Admin checkbox, for example
		                        />
		                    </div>
		                );
		            }
		        }
		    }))
		];

		setColumns(columnList);
    }

    const createFeatureColumns = () => {
    	const columnList = [
		    {
		        name: "featureName",
		        label: 'Feature Name',
		        options: {
		        	filter: true,
		        	sort: true,
		        	customBodyRender: (value, tableMeta, updateValue) => {
		              	return (
		                	<div className="fw-bold">
		                  		{value}
		                	</div>
		              	);
		          	}
		        }
	    	},
	    	...roleList.map(role => ({
		        name: "shortName",
		        label: role, // Set the label as the role (e.g., Administrator)
		        options: {
		            filter: true,
		            sort: true,
		            customBodyRender: (value) => {
		                const pageObj = featureList.find(feature => feature?.shortName === value);
		                const permission = pageObj.permission || [];

		                const roleWisePermissionObj = permission.find(per => per?.role === role);
		                const accessValue = roleWisePermissionObj?.access || false;
		                return (
		                    <div>
		                        <CheckBoxView
		                            value={accessValue}
		                            onChange={() => onHandleFeatureChange(value, role, accessValue)}
		                            isDisabled={role === 'administrator'} // Disable the Admin checkbox, for example
		                        />
		                    </div>
		                );
		            }
		        }
		    })
		    )
		];

		setFeatureColumns(columnList);
    }

    const onHandleAccessChange = (page_url = "", role = "", accessValue = "") => {
    	
    	const updateAccessList = [...accessList];
    	const filterAccessInx = updateAccessList.findIndex((acc) => acc?.page_url === page_url);

    	if(filterAccessInx != -1) {
    		const filterRoleIndex = updateAccessList[filterAccessInx]?.permission?.findIndex((permissionRole, idx) => permissionRole?.role === role);
    		
    		updateAccessList[filterAccessInx].permission[filterRoleIndex].access = !accessValue;
    		setAccessList(updateAccessList);
    	}
    }

    const onHandleFeatureChange = (shortName = "", role = "", featureValue = "") => {
    	const updateFeatureList = [...featureList];
    	const filterFeatureInx = updateFeatureList.findIndex((feature, index) => feature?.shortName === shortName);
    	
    	if(filterFeatureInx != -1) {
    		const filterRoleIndex = updateFeatureList[filterFeatureInx]?.permission?.findIndex((permissionRole, idx) => permissionRole?.role === role);
    		updateFeatureList[filterFeatureInx].permission[filterRoleIndex].access = !featureValue;
    		setFeatureList(updateFeatureList);
    	}
    }

  	const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: '',
	      direction: 'asc'
	    }
  	};

  	const onHandleSubmit = async () => {
  		/*if(addNewPage) {
  			const newPageAccessDetails = {
	            page_url: "/attendance-approval",
	            page_name: "Attendance Approval",
	            permission: [
	                {
	                    role: "administrator",
	                    access: true
	                },
	                {
	                    role: "sub-administrator",
	                    access: false
	                },
	                {
	                    role: "manager",
	                    access: false
	                },
	                {
	                    role: "general",
	                    access: false
	                },
	                {
	                    role: "hr",
	                    access: false
	                }
	            ]
        	};

        	if(accessList.findIndex(item => item?.page_url === newPageAccessDetails?.page_url) === -1) {
        		accessList.push(newPageAccessDetails);
        	}
  		}
  		*/
  		

  		//accessList.splice(9, 1);
  		
  		const obj = overAllAccessList;
  		obj.accessControlList = accessList;
  		obj.featureControlList = featureList;

  		console.log("onHandleSubmit --->", obj);

  		await dispatch(updateWebAccessControlByLEAction(filterDetails?.emp_legal_entity_id, obj));

  	}

	return (
		<div className="my-4">
      		<TableView
              clsName = "webaccess"
              title="Page Access Details" 
              columns={columns} 
              data={accessList} 
              tableOptions = {options} 
            />

            <div className="my-5">
	            <TableView
	              clsName = "webaccess"
	              title="Feature Access Details" 
	              columns={columns_feature} 
	              data={featureList} 
	              tableOptions = {options} 
	            />
            </div>

            <div className="w-100 d-flex justify-content-end mt-3">
                <button type="submit" className="btn btn-danger mt-3 ms-3" onClick = {onHandleSubmit}>Update</button>
            </div>

            {loading && <Loading />}
            {error && <CustomizedSnackbars />}
		</div>
	)
}

export default WebAccessControl;