import { DocumentScanner } from "@mui/icons-material";
import leave from '../assets/images/ic_leave_approval.png';
import access_Control_Icon from '../assets/images/Access_Control_Icon.png';
import attendance_approval from '../assets/images/attendance-approval.png';
import attendance_report_icon from '../assets/images/attendance_report_icon.png';
import roster_icon from '../assets/images/roster_icon.png';
import employee_icon from '../assets/images/employee_icon.png';
import ofc_icon from '../assets/images/ofc.png';

const sidebarNav = [
    
    {
        link: '/report',
        section: 'report',
        icon: <img src={attendance_report_icon} style={{width: '30px'}} alt="" />,
        text: 'report'
    },
    {
        link: '/employes',
        section: 'employes',
        icon: <img src={employee_icon} style={{width: '30px'}} alt="" />,
        text: 'Employes'
    },
    {
        link: '/offices',
        section: 'offices',
        icon: <img src={ofc_icon} style={{width: '30px'}} alt="" />,
        text: 'Offices'
    },
    {
        link: '/leaves',
        section: 'leaves',
        icon: <img src={leave} alt="" />,
        text: 'Leaves'
    },
    {
        link: '/attendance-approval',
        section: 'attendance-approval',
        icon: <img src={attendance_approval} alt="" />,
        text: 'Leave Approval'
    },
    {
        link: '/access-control',
        section: 'access-control',
        icon: <img src={access_Control_Icon} />,
        text: 'Access Control'
    },
    {
        link: '/roster',
        section: 'roster',
        icon: <img src={roster_icon} style={{width: '30px'}} alt="" />,
        text: 'Roster'
    },
    {
        link: '/logs',
        section: 'logs',
        icon: <DocumentScanner alt="" />,
        text: 'Log'
    },
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bxs-cog'></i>,
        text: 'Settings'
    }
]

export default sidebarNav