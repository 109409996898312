import React, {useState} from 'react';
import Table from '../../components/table/Table';
import EditJobRole from "./EditJobRole";
import ConfirmPayroll from './ConfirmPayroll';
import Tooltip from '@mui/material/Tooltip';
import job_role_icon from '../../assets/images/job_role_icon.png';
import {InititalJobRoleData} from '../../util/DefaultInitialValues';

const JobRoleConfigure = ({data}) => {

  const [details, setDetails] = useState(null);
  const [isShow, setShow] = useState(false);

  const [isShowConfirm, setShowConfirm] = useState(false);

	const columns = [
    {
      name: "job_role_id",
      label: 'ID',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "job_role_name",
      label: 'Name',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
     
          const key_val = tableMeta.rowData[0];

          return (
            <div className="d-flex align-items-center w-100">
              <button className="rounded-btn fs-6 mx-2" title='Edit' onClick={() => handleEdit(key_val)} >
                <i className='bx bx-edit'></i>
                <span className="px-2">Edit</span>
              </button>

              <button className="rounded-btn fs-6 mx-2" title='Delete' onClick={() => handleDelete(key_val)} >
                <i className='bx bx-message-alt-x' ></i>
                <span className="px-2">Delete</span>
              </button>
            </div>
          );
        }
      },
    }
  ];

  const addPayroll = () => {
    setDetails(InititalJobRoleData);
    setShow(true);
  }

  const AddButton = () => (
    <Tooltip disableFocusListener title='Add JobRole'>
      <button className="MuiButtonBase-root MuiIconButton-root ax-button" onClick={() => addPayroll()} >
        <img src={job_role_icon} style={{width: '20px'}} alt = "" />
      </button>
    </Tooltip>
  );

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    print: true,
    rowsPerPage: 100,
    customToolbar: AddButton,
    sortOrder: {
      name: 'job_role_name',
      direction: 'asc'
    }
  };

  const handleEdit = (val) => {

    const filterItem = data && data.filter((item, index) => item.job_role_id === val);

    if(filterItem && filterItem.length > 0) {
      setDetails(filterItem[0]);
      setShow(true);
    }
  }

  const handleDelete = (val) => {

    const filterItem = data && data.filter((item, index) => item.job_role_id === val);
    if(filterItem && filterItem.length > 0) {
      setDetails(filterItem[0]);
      setShowConfirm(true);
    }
  }

	return (
		<div className="my-5">
  		<div className="container-fluid">
			<Table headers={columns} rowData={data} options={options} clsName = "jobrole" />
      {isShow && <EditJobRole isOpen={isShow} details={details} onHandleCloseDialog = {() => setShow(false)} />}
      {isShowConfirm && <ConfirmPayroll pageName="Job Role" details={details} isOpen={isShowConfirm} onHandleCloseDialog = {() => setShowConfirm(false)} /> }
			</div>
		</div>
	)
}

export default JobRoleConfigure;