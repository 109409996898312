import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {isEmptyJson} from '../../util/utils';
import {baseURL} from '../../actions/Constants';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import {getTimeStampsFromDate} from '../../util/DateTimeConverter';
import Loading from '../../components/loading/Loading';
import TabView from '../../components/tabView/TabView';
import DatepickerView from '../../components/datepickerView/DatepickerView';
import MobileLog from './MobileLog';
import leave from '../../assets/images/ic_leave_approval.png';

const Logs = () => {
	const [filters, setFilters] = useState(null);
	
	const [viewIndex, setViewIndex] = useState(0);
	const [allLogs, setAllLogs] = useState([]);
	const [userMobileLogs, setuserMobileLogs] = useState([]);
	const [date, setDate] = useState(new Date());
	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

	const TabsData = ["Mobile Log", "Web", "Platform"];
	const titleVal = "Logs - OneAttendance";
	const {uid} = useParams();

	const {
	    details
  	} = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));
  	
  	const {
        details: loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));


  	useEffect(() => {
	    if(details && !isEmptyJson(details)) {
	      	setFilters(details);
	    }
  	}, [details]);

  	useEffect(() => {
	    if(filters !== null && emp_legal_entity_id !== "") {
	      	onFetchLeaveDetails();
	    }
  	}, [filters]);

  	useEffect(() => {
	    // Add a class to the body tag
	    document.body.classList.add('logs');

	    // Cleanup: Remove the class when the component unmounts
	    return () => {
	      document.body.classList.remove('logs');
	    };
    }, []);

	const getUserLogByDate = async () => {
		setLoading(true);
		const URL = `${baseURL}/getUserLogByDate`;
		const startTimeStampValue = getTimeStampsFromDate(date);
	    
	    let obj = {};
	    obj.emp_id = uid || loggedAdminDetails?.emp_id;
		obj.datetimestamp = startTimeStampValue; //1735842600000
		obj.legal_entity_id = emp_legal_entity_id;

		return await fetch(URL, {
			headers: {
      			'Content-Type': 'application/json',
    		},
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
      		body: JSON.stringify(obj) // body data type must match "Content-Type" header
			
    	})
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	const allLogs = res && res[0] || [];
		      	
		      	if(allLogs && allLogs?.mobile_log) {
		      		const mobile_log = JSON.parse(allLogs?.mobile_log);
		      		
		      		if(mobile_log && Array.isArray(mobile_log?.user_device_log_trail)) {
		      			//updateLeaveStates(allLogs);
		      			setuserMobileLogs(mobile_log?.user_device_log_trail)
		      		}
		      	}
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getUserLogByDate', error);
		});
	}

	const onFetchLeaveDetails = () => {
		getUserLogByDate();
	}

	const {
	    emp_legal_entity_id = ""
  	} = filters || {};

  	const onSubmit = (e) => {
  		e.preventDefault();
  		onFetchLeaveDetails();
  	}
  	
	return (
		<>
	      	<Helmet>
	        	<title>{titleVal}</title>
	        	<meta name="description" content="Login meta"></meta>
	        </Helmet>
	      	
	      	<div className=" mb-3 filter_section leave">
		        <div className="d-flex justify-content-between align-items-center mb-3">
		          <div className="d-flex align-items-center">
		            <div> 
		              <img src={leave} className="w-100" alt= "" /> 
		            </div>

		            <div className="ps-3">
		              <div className="page_h1_heading">Logs</div>
		              <div className="page_le_text">{filters && filters?.emp_legal_entity_id}</div>
		            </div>
		          </div>
		        </div>
		        <div className="d-flex justify-content-between">
	        		<div className="mx-5">
	        			<DatepickerView value = {date} onDatePickerUpdate = {d => setDate(d)} />
	        		</div>
	        		<button className="btn btn-outline-danger ms-3 py-2 go-btn" onClick={onSubmit} disabled={false}>Go</button>
    
	        	</div>
	      	</div>

	      	<TabView TabsData = {TabsData} onTabClick = {(idx) => setViewIndex(idx) } />
	      	{viewIndex === 0 && <MobileLog  logs = {userMobileLogs} emp_id = {loggedAdminDetails?.emp_id} />}
	      	
	      	{isError && <CustomizedSnackbars />}
	      	{isLoading && <Loading />}
	    </>
	)
}

export default Logs;